import {
    Container,
    VStack,
    Card,
    HStack,
    Button,
    Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useQuotationMasters } from '../providers/QuotationMastersProvider';
import { Link } from 'react-router-dom';
import { ConfirmDialog } from './utils/ConfirmDialog';

export default function QuotationMasters() {
    var { quotationmasters, deleteQuotationMaster } = useQuotationMasters();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);


    const handleConfirm = async () => {
        //when the user confirms the dialog
        try {
            await deleteQuotationMaster(selectedId);
            setIsOpen(false);
        } catch (error) {

        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Container p='4' maxW='4xl'>
            <VStack width='100%' spacing={4}>

                <Card borderRadius='md' p='4' width={'100%'}>
                    <HStack justifyContent='space-between'>
                        <Text fontWeight='bold' className='customfont' fontSize='2xl'>Quotation Masters</Text>
                        <Link to={'/quotationmasters/add'}> <Button colorScheme='purple'>Add</Button> </Link>
                    </HStack>
                </Card>

                <Card width={'100%'} borderRadius='md' p={6}>
                    <table>
                        <thead>
                            <tr>
                                <th py='4' fontSize='sm'>S.no</th>
                                <th py='4' fontSize='sm'>Name</th>
                                <th py='4' fontSize='sm'>Address</th>
                                <th py='4' fontSize='sm'>GST</th>
                                <th py='4' fontSize='sm'>To Name</th>
                                <th py='4' fontSize='sm'>To Address</th>
                                <th py='4' fontSize='sm'>Prop Name</th>
                                <th py='4' fontSize='sm'>Prop No</th>
                                <th py='4' fontSize='sm'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {quotationmasters.map((quotation, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td py='4' align='center' fontSize='sm'>{quotationmasters.indexOf(quotation) + 1}</td>
                                        <td py='4' align='center' fontSize='sm'> {quotation.name}</td>
                                        <td py='4' align='center' fontSize='sm'> {quotation.address}</td>
                                        <td py='4' align='center' fontSize='sm'> {quotation.gst}</td>
                                        <td py='4' align='center' fontSize='sm'>{quotation.to_name}</td>
                                        <td py='4' align='center' fontSize='sm'>{quotation.to_address}</td>
                                        <td py='4' align='center' fontSize='sm'>{quotation.prop_name}</td>
                                        <td py='4' align='center' fontSize='sm'>{quotation.prop_number}</td>

                                        <td>
                                            <HStack justifyContent='center'>
                                                <Button size='sm' onClick={() => { setSelectedId(quotation.id); setIsOpen(true); }} colorScheme='red'>Delete</Button>
                                                <Link to={`/quotationmasters/edit/${quotation.id}`} state={quotation}>
                                                    <Button size='sm' colorScheme='purple'>Edit</Button>
                                                </Link>
                                            </HStack>
                                        </td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                </Card>


            </VStack>

            <ConfirmDialog
                isOpen={isOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                title="Are you sure?"
                body="This action cannot be undone."
            />


        </Container >
    )
}


