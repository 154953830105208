import {
    Container,
    VStack,
    Card,
    HStack,
    Button,
    Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { usePlansProducts } from '../providers/PlansProductsProvider';
import { Link } from 'react-router-dom';
import { ConfirmDialog } from './utils/ConfirmDialog';

export default function PlanProducts() {
    var { plansproducts, deletePlansProducts } = usePlansProducts();
    console.log("From plansproducts")
    console.log(plansproducts)
    const [isOpen, setIsOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);


    const handleConfirm = async () => {
        //when the user confirms the dialog
        try {
            await deletePlansProducts(selectedId);
            setIsOpen(false);
        } catch (error) {

        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Container p='4' maxW='5xl'>
            <VStack width='100%' spacing={4}>

                <Card borderRadius='md' p='4' width={'100%'}>
                    <HStack justifyContent='space-between'>
                        <Text fontWeight='bold' className='customfont' fontSize='2xl'>Plans Products</Text>
                        <Link to={'/plans/add'}> <Button colorScheme='purple'>Add</Button> </Link>
                    </HStack>
                </Card>

                <Card width={'100%'} borderRadius='md' p={6}>
                    <table>
                        <thead>
                            <tr>
                                <th py='4' fontSize='sm'>S.no</th>
                                <th py='4' fontSize='sm'>Title</th>
                                <th py='4' fontSize='sm'>Days</th>
                                <th py='4' fontSize='sm'>HSN/SAC code</th>
                                <th py='4' fontSize='sm'>Price</th>
                                {/* <th py='4' fontSize='sm'>CGST</th>
                                <th py='4' fontSize='sm'>SGST</th>
                                <th py='4' fontSize='sm'>IGST</th> */}
                                <th py='4' fontSize='sm'>Note</th>
                                <th py='4' fontSize='sm'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {plansproducts.map((p, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td py='4' align='center' fontSize='sm'>{plansproducts.indexOf(p) + 1}</td>
                                        <td py='4' align='center' fontSize='sm'> {p.title}</td>
                                        <td py='4' align='center' fontSize='sm'> {p.duration_in_days}</td>
                                        <td py='4' align='center' fontSize='sm'> {p.hsncode}</td>
                                        <td py='4' align='center' fontSize='sm'> {p.cost}</td>
                                        {/* <td py='4' align='center' fontSize='sm'> {p.sgst_percent} %</td>
                                        <td py='4' align='center' fontSize='sm'> {p.cgst_percent} %</td>
                                        <td py='4' align='center' fontSize='sm'> {p.igst_percent} %</td> */}
                                        <td py='4' align='center' fontSize='sm'> {p.note}</td>
                                        <td>
                                            <HStack justifyContent='center'>
                                                <Button size='sm' onClick={() => { setSelectedId(p.id); setIsOpen(true); }} colorScheme='red'>Delete</Button>
                                                <Link to={`/plans/edit/${p.id}`} state={p}>
                                                    <Button size='sm' colorScheme='purple'>Edit</Button>
                                                </Link>
                                            </HStack>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                </Card>


            </VStack>

            <ConfirmDialog
                isOpen={isOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                title="Are you sure?"
                body="This action cannot be undone."
            />


        </Container >
    )
}



