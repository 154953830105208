import { Button, Card, Container, FormControl, FormLabel, Input, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCustomers } from '../providers/CustomersProvider';
import { toast } from 'react-toastify';

export default function EditCustomer() {

    const { state } = useLocation();
    const navigate = useNavigate();

    const { editCustomer } = useCustomers();

    const formik = useFormik({

        initialValues: {
            name: state.name,
            email: state.email,
            phone: state.phone,
            gst: state.gst,
            note: state.note,
            address: state.address,
        },
        onSubmit: async (values) => {
            try {
                await editCustomer(state.id, values.name, values.email, values.phone, values.gst, values.note, values.address);
                navigate(-1);
                toast.success("Customer Updated Successfully !")
            }
            catch {
                toast.error("Error While Updating Customer")
            }
        }
    })

    return (
        <Container p={4} maxW='3xl' >
            <VStack>
                <Card p={4} w={'100%'}> <Text fontSize='2xl' fontWeight={'bold'}>Edit Customer</Text> </Card>

                <Card p={6} w={'100%'}>
                    <form onSubmit={formik.handleSubmit}>
                        <VStack spacing={4} align="flex-start">
                            <FormControl>
                                <FormLabel htmlFor='name'>Name</FormLabel>
                                <Input
                                    id='name'
                                    name='name'
                                    type='text'
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor='email'>Email</FormLabel>
                                <Input
                                    id='email'
                                    name='email'
                                    type='email'
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Phone</FormLabel>
                                <Input
                                    id='phone'
                                    name='phone'
                                    type='text'
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="gst">GST</FormLabel>
                                <Input
                                    id="gst"
                                    name="gst"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.gst}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor='note'>Note</FormLabel>
                                <Input
                                    id='note'
                                    type='text'
                                    name='note'
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.note}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="address">Address</FormLabel>
                                <Input
                                    id="address"
                                    name="address"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.address}
                                />
                            </FormControl>

                            <Button type="submit" colorScheme="purple" py='6' width="full">
                                Update
                            </Button>

                        </VStack>
                    </form>
                </Card>
            </VStack>
        </Container>
    )
}
