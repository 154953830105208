import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { useAuth } from "./AuthProvider";

const OrdersContext = createContext();

const OrdersProvider = ({ children }) => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logged, logout } = useAuth();

    async function getOrders() {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            setOrders([]);
            var { data } = await axios.get(`${url}/orders`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                }
            );
            setLoading(false);
            setOrders(data);

        } catch (error) {
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    async function deleteOrders(id) {
        try {
            await axios.delete(`${url}/orders/${id}`);
            await getOrders();
            console.log("from try")
            return;
        }
        catch (err) {
            console.log("from catch")
            console.log(err)
        }
    }

    useEffect(() => {
        getOrders();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            orders,
            loading,
            getOrders,
            deleteOrders
        }),
        [loading, orders]); // eslint-disable-line react-hooks/exhaustive-deps

    // Provide the authentication context to the children components
    return (
        <OrdersContext.Provider value={contextValue}>{children} </OrdersContext.Provider>
    );
};

export const useOrders = () => {
    return useContext(OrdersContext);
};

export default OrdersProvider;