import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { useAuth } from "./AuthProvider";

const QuotationMastersContext = createContext();

const QuotationMastersProvider = ({ children }) => {
    const [quotationmasters, setQuotationmasters] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logged, logout } = useAuth();

    // const { token, logged, logout } = useAuth();


    async function addQuotationMaster(name, address, gst, to_name, to_address, prop_name, prop_number) {
        setLoading(true);
        try {
            await axios.post(`${url}/quotationmaster`, {
                "name": name,
                "address": address,
                "gst": gst,
                "to_name": to_name,
                "to_address": to_address,
                "prop_name": prop_name,
                "prop_number": prop_number
            },
            );
            await getQuotationMasters();
            return;
        } catch (error) {
            console.error(error);
            return "err";
        }
    }

    async function deleteQuotationMaster(id) {
        try {
            console.log(id)
            await axios.delete(`${url}/quotationmaster/${id}`);
            await getQuotationMasters();
            return;
        }
        catch (err) {
            console.log(err)
        }
    }

    async function editQuotationMaster(id, name, address, gst, to_name, to_address, prop_name, prop_number) {
        setLoading(true);
        try {

            await axios.put(`${url}/quotationmaster/${id}`, {
                "name": name,
                "address": address,
                "gst": gst,
                "to_name": to_name,
                "to_address": to_address,
                "prop_name": prop_name,
                "prop_number": prop_number
            }
            );
            await getQuotationMasters();
            return;
        }
        catch (err) {
            console.log(err);
        }
    }

    async function getQuotationMasters() {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/quotationmasters`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                }
            );
            setLoading(false);
            setQuotationmasters(data);

        } catch (error) {
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    useEffect(() => {
        getQuotationMasters();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            quotationmasters,
            loading,
            getQuotationMasters,
            addQuotationMaster,
            deleteQuotationMaster,
            editQuotationMaster
        }),
        [loading, quotationmasters]); // eslint-disable-line react-hooks/exhaustive-deps

    // Provide the authentication context to the children components
    return (
        <QuotationMastersContext.Provider value={contextValue}>{children} </QuotationMastersContext.Provider>
    );
};

export const useQuotationMasters = () => {
    return useContext(QuotationMastersContext);
};

export default QuotationMastersProvider;