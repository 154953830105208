import { Container, Card, Text, Button, Box, VStack, HStack, Divider } from '@chakra-ui/react'
import moment from 'moment';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

export default function PrintQuotation() {

    const componentRef = useRef();

    const { state } = useLocation();


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    console.log(state);

    return (
        <>
            <Container maxW='5xl'>
                <VStack my='2'>

                    <Card p='5' w='100%'>
                        <HStack justifyContent='space-between' w='100%'>
                            <Text fontWeight='800'>Quotation Details</Text>
                            <Button onClick={handlePrint}>Print</Button>
                        </HStack>
                    </Card>

                    <Box bg='white' ref={componentRef} shadow='md' p='5' style={{ height: 'auto', width: '21cm', aspectRatio: '1.41/1' }}>

                        <Container border='2px solid #1c1c1c' width='100%' maxW='100%'>

                            <HStack alignItems='start' justifyContent='space-between' p='0' w='100%'>

                                <VStack border='0px solid #1c1c1c' w='100%' spacing='0' justifyContent='start'>
                                    <Text mt='2' fontSize='2xl' fontWeight='800'>{state.quotation.name}</Text>
                                    <Text>{state.quotation.address}</Text>


                                    <VStack alignItems='start' w='100%' spacing='1' my='4'>
                                        <Text>Prop : {state.quotation.prop_name}</Text>
                                        <Text>Contact : {state.quotation.prop_number}</Text>
                                        <Text>Date : {moment(state.date).format("DD/MM/yyyy")}</Text>
                                    </VStack>

                                    <Text>
                                        **************************************************************************************************
                                    </Text>

                                </VStack>
                            </HStack>

                            <Text>To</Text>
                            <HStack justifyContent='space-between'>
                                <Text>Name : {state.clientName}</Text>
                            </HStack>

                            <HStack justifyContent='space-between'>
                                <Text>{state.clientName}</Text>
                            </HStack>

                            <Divider my='3' />

                            <HStack justifyContent='space-between'>
                                <Text>Sub : {state.clientSub}</Text>
                            </HStack>


                            <table style={{ width: '100%' }}>
                                <thead>
                                    <th>S.No</th>
                                    <th>Particular</th>
                                    <th>Validity</th>
                                    {/* <th>HSN Code</th> */}
                                    {/* <th>Qty</th> */}
                                    <th>Rate</th>
                                    <th>Amount</th>
                                </thead>
                                <tbody>
                                    {state.items.map((item, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>1</td>
                                                <td>{item.plan_title}</td>
                                                <td>{item.duration_in_days}</td>
                                                {/* <td>{item.hsncode}</td> */}
                                                {/* <td>{item.qty}</td> */}
                                                <td>{item.cost}</td>
                                                <td>{item.cost * item.qty}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {/* <td></td> */}
                                        <td>Net Amount</td>
                                        {/* <td> </td> */}
                                        <td>{state.total}</td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* <HStack justifyContent='end' my='5'>
                                <Image src='/sign.jpeg' w='120px' />
                            </HStack> */}

                            {/* <Text my='2' textAlign='center' textDecoration='underline'>This is a computer generated bill</Text> */}

                            <Box mb='2' />

                        </Container>
                    </Box>
                </VStack>
            </Container>
        </>
    )
}