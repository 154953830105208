import { Card, Container, VStack, Text, HStack, Button, Input } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useCustomers } from '../providers/CustomersProvider';
import { usePlansProducts } from '../providers/PlansProductsProvider';
import { MdDelete } from "react-icons/md";
import { Select } from 'chakra-react-select';
import { url } from '../config';
import { useNavigate } from 'react-router-dom';
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import axios from 'axios';
import moment from 'moment';

export default function AddOrders() {
  const navigate = useNavigate();

  var { customers } = useCustomers();
  var { plansproducts } = usePlansProducts();

  // const [date_from, setDateFrom] = useState(new Date());
  // const [date_to, setDateTo] = useState(new Date());

  const [customersDropdown, setCustomersDropdown] = useState([]);
  const [selectedCustomer, setselectedCustomer] = useState([]);
  const [plansDropdown, setPlansDropdown] = useState({});
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [addedPlans, setAddedPlans] = useState([]);
  const [cgst, setCgst] = useState(9);
  const [sgst, setSgst] = useState(9);
  const [totalAmount, setTotalAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  function calculateTotal() {
    // get all selected plans and cgst percentages and calculate total
    let tempInternalTotal = 0;
    for (var p of addedPlans) {
      tempInternalTotal = tempInternalTotal + (parseInt(p.cost) * p.qty);
    }
    setTotalAmount(tempInternalTotal);
    // 
    let cgstCost = (cgst / 100) * tempInternalTotal;
    let sgstCost = (sgst / 100) * tempInternalTotal;
    setGrandTotal(totalAmount + cgstCost + sgstCost);
  }

  const handlePlanChange = (event) => {
    setSelectedPlan(event.value);
    calculateTotal();
  };

  const handleAddPlan = () => {
    if (selectedPlan) {
      const isSelectedPlan = addedPlans.filter((plan) => plan === selectedPlan);
      if (!isSelectedPlan.length) {
        let tempSelectedPlan = selectedPlan;
        tempSelectedPlan.qty = 1;
        tempSelectedPlan.date_from = moment().format("yyyy-MM-DD");
        tempSelectedPlan.date_from_date = new Date();
        tempSelectedPlan.date_to = moment().format("yyyy-MM-DD");
        tempSelectedPlan.date_to_date = new Date();
        setAddedPlans([...addedPlans, tempSelectedPlan]);
      }
      setSelectedPlan(null);
    }
  };

  const handleRemovePlan = (planIndex) => {
    setAddedPlans((prevPlans) => prevPlans.filter((plan, index) => index !== planIndex));
  };

  useEffect(() => {
    let tempcustomers = [];
    for (var c of customers) {
      tempcustomers.push({
        label: c.name,
        value: c.id,
      });
    }
    setCustomersDropdown(tempcustomers);
  }, [customers]);

  useEffect(() => {
    console.log(plansproducts)
    let tempplans = [];
    for (var p of plansproducts) {
      tempplans.push({
        label: p.title,
        value: p,
      });
    }
    setPlansDropdown(tempplans);
  }, [plansproducts]);

  useEffect(() => {
    calculateTotal();
  }, [addedPlans]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    calculateTotal();
  }, [totalAmount, cgst, sgst]); // eslint-disable-line react-hooks/exhaustive-deps


  async function callOrderAPI() {
    try {
      await axios.post(`${url}/orders`, {
        "uid": selectedCustomer.value,
        "products": JSON.stringify(addedPlans),
        "cgst": cgst,
        "sgst": sgst,
        "round_off": "0.0",
        "total": grandTotal,
      },
        navigate("/orders")
        // {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     },
        // }
      );
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Container p={4} maxW='6xl'>
        <Card p={4} width={'100%'}><Text fontSize='2xl' fontWeight={'bold'}>Add Orders</Text></Card>
        <VStack my={4} align={'start'} width={'100%'}>
          <HStack width={'100%'} justify={'center'}>
            <Card p={4} width={'100%'} >
              <VStack>
                <Text fontSize='1xl' fontWeight={'bold'}>Select Customer</Text>
                <Select
                  variant='filled'
                  options={customersDropdown}
                  placeholder='Select Customer'
                  onChange={(customer) => setselectedCustomer(customer)}
                // onChange={(customer) => setselectedCustomer(customer.label)}
                />
              </VStack>
            </Card>
            {selectedCustomer !== "" ?
              <Card p={4} width={'100%'}>
                <VStack w='100%'>
                  <Text fontSize='1xl' fontWeight={'bold'}>Select Plans</Text>
                  <HStack>
                    <Select
                      variant='filled'
                      options={plansDropdown}
                      onChange={handlePlanChange}
                      placeholder='Select Plans'
                    />
                    <Button colorScheme="purple" onClick={handleAddPlan}>Add</Button>
                  </HStack>
                </VStack>
              </Card> : <></>}
          </HStack>

          {addedPlans !== 0 ?
            <Card p={4} width={'100%'}>
              <table>
                <thead>
                  <tr>
                    <th>Plan Name</th>
                    <th>Validity</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {addedPlans.map((plan, index) => (
                    <tr key={plan.id}>
                      <td>{plan.title}</td>
                      <td>{plan.duration_in_days} days</td>
                      <td>
                        <SingleDatepicker
                          name="date-input"
                          date={plan.date_from_date}
                          configs={{
                            dateFormat: 'dd-MM-yyyy',
                          }}
                          onDateChange={(d) => {
                            // setDateTo(d);
                            plan.date_from = moment(d).format("yyyy-MM-DD");
                            plan.date_from_date = d;
                            let newUpdatedPlan = plan;
                            let oldarr = addedPlans;
                            oldarr[addedPlans.indexOf(plan)] = newUpdatedPlan;
                            setAddedPlans(oldarr);
                            calculateTotal();
                          }}
                        />
                      </td>
                      <td>
                        <SingleDatepicker
                          name="date-input"
                          date={plan.date_to_date}
                          configs={{
                            dateFormat: 'dd-MM-yyyy',
                          }}
                          onDateChange={(d) => {
                            // setDateTo(d);
                            plan.date_to = moment(d).format("yyyy-MM-DD");
                            plan.date_to_date = d;
                            let newUpdatedPlan = plan;
                            let oldarr = addedPlans;
                            oldarr[addedPlans.indexOf(plan)] = newUpdatedPlan;
                            setAddedPlans(oldarr);
                            calculateTotal();
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          width={'100px'}
                          type='number'
                          defaultValue={plan.qty}
                          onChange={(val) => {
                            // console.log(val.target.value);
                            plan.qty = val.target.value || 1;
                            let newUpdatedPlan = plan;
                            let oldarr = addedPlans;
                            oldarr[addedPlans.indexOf(plan)] = newUpdatedPlan;
                            setAddedPlans(oldarr);
                            calculateTotal();
                            // setTotalAmount(totalAmount + (plan.cost * plan.qty)); 
                          }}
                        />
                      </td>
                      <td>₹{plan.cost}</td>
                      <td>₹{parseInt(plan.cost) * plan.qty}</td>
                      <td><Button onClick={() => handleRemovePlan(index)}><MdDelete /></Button></td>
                    </tr>
                  ))}

                  <tr style={{ backgroundColor: "transparent" }}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    <td>₹{totalAmount}</td>
                    <td></td>
                  </tr>

                  <tr style={{ backgroundColor: "transparent" }}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>sgst %</td>
                    <td>
                      <Input
                        width={'100px'}
                        type='number'
                        defaultValue={sgst}
                        onChange={(val) => {
                          console.log(val.target.value)
                          setSgst(val.target.value);
                          calculateTotal();
                        }}
                      />
                    </td>
                    <td>{((sgst / 100) * totalAmount).toFixed(2)}</td>
                  </tr>

                  <tr style={{ backgroundColor: "transparent" }}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>cgst %</td>
                    <td>
                      <Input
                        width={'100px'}
                        type='number'
                        defaultValue={cgst}
                        onChange={(val) => {
                          setCgst(val.target.value);
                          calculateTotal();
                        }}
                      />
                    </td>
                    <td>{((cgst / 100) * totalAmount).toFixed(2)}</td>
                  </tr>

                  <tr style={{ backgroundColor: "transparent" }}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Grand Total</td>
                    <td>₹{grandTotal.toFixed(2)}</td>
                  </tr>

                  <tr style={{ backgroundColor: "transparent" }}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Round Off</td>
                    <td>₹{(Math.ceil(grandTotal) - grandTotal).toFixed(2)}</td>
                  </tr>

                  <tr style={{ backgroundColor: "transparent" }}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Final Amount </td>
                    <td>₹{Math.ceil(grandTotal)}</td>
                  </tr>
                </tbody>
              </table>
              <Button mt={6} colorScheme="purple" onClick={callOrderAPI}>Order Now</Button>
            </Card>
            : <></>}
        </VStack>
      </Container>
    </>
  )
}
