import { Card, Container, VStack, Text, HStack, Button, Input } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { usePlansProducts } from '../providers/PlansProductsProvider';
import { MdDelete } from "react-icons/md";
import { Select } from 'chakra-react-select';
import moment from 'moment';
import { useQuotationMasters } from '../providers/QuotationMastersProvider';
import { Link } from 'react-router-dom';

export default function GenerateQuotation() {

    var { plansproducts } = usePlansProducts();
    var { quotationmasters } = useQuotationMasters();

    // const [date_from, setDateFrom] = useState(new Date());
    // const [date_to, setDateTo] = useState(new Date());

    const [plansDropdown, setPlansDropdown] = useState({});
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [quotationDropdown, setQuotationDropdown] = useState({});
    const [selectedQuotation, setSelectedQuotation] = useState([]);
    const [addedPlans, setAddedPlans] = useState([]);
    // const [cgst, setCgst] = useState(9);
    // const [sgst, setSgst] = useState(9);
    const [totalAmount, setTotalAmount] = useState(0);

    const [clientName, setClientName] = useState("");
    const [clientAddress, setClientAddress] = useState("");
    const [clientSub, setClientSub] = useState("");

    function calculateTotal() {
        // get all selected plans and cgst percentages and calculate total
        let tempInternalTotal = 0;
        for (var p of addedPlans) {
            tempInternalTotal = tempInternalTotal + (parseInt(p.cost) * p.qty);
        }
        setTotalAmount(tempInternalTotal);
        // 
        // let cgstCost = (cgst / 100) * tempInternalTotal;
        // let sgstCost = (sgst / 100) * tempInternalTotal;
        // setGrandTotal(totalAmount + cgstCost + sgstCost);
    }

    const handlePlanChange = (event) => {
        setSelectedPlan(event.value);
        calculateTotal();
    };

    const handleAddPlan = () => {
        if (selectedPlan) {
            const isSelectedPlan = addedPlans.filter((plan) => plan === selectedPlan);
            if (!isSelectedPlan.length) {
                let tempSelectedPlan = selectedPlan;
                tempSelectedPlan.qty = 1;
                tempSelectedPlan.date_from = moment().format("yyyy-MM-DD");
                tempSelectedPlan.date_from_date = new Date();
                tempSelectedPlan.date_to = moment().format("yyyy-MM-DD");
                tempSelectedPlan.date_to_date = new Date();
                setAddedPlans([...addedPlans, tempSelectedPlan]);
            }
            setSelectedPlan(null);
        }
    };

    const handleRemovePlan = (planIndex) => {
        setAddedPlans((prevPlans) => prevPlans.filter((plan, index) => index !== planIndex));
    };


    useEffect(() => {
        console.log(plansproducts)
        let tempplans = [];
        for (var p of plansproducts) {
            tempplans.push({
                label: p.title,
                value: p,
            });
        }
        setPlansDropdown(tempplans);
    }, [plansproducts]);

    useEffect(() => {
        console.log(quotationmasters)
        let tempquotations = [];
        for (var p of quotationmasters) {
            tempquotations.push({
                label: p.name,
                value: p,
            });
        }
        setQuotationDropdown(tempquotations);
    }, [quotationmasters]);

    useEffect(() => {
        calculateTotal();
    }, [addedPlans]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        calculateTotal();
    }, [totalAmount]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <Container p={4} maxW='6xl'>
                <Card p={4} width={'100%'}><Text fontSize='2xl' fontWeight={'bold'}>Generate Quotation</Text></Card>
                <VStack my={4} align={'start'} width={'100%'}>
                    <HStack width={'100%'} justify={'center'}>
                        <Card p={4} width={'100%'} >
                            <VStack>
                                <Text fontSize='1xl' fontWeight={'bold'}>Select Quotation Account</Text>
                                <Select
                                    variant='filled'
                                    options={quotationDropdown}
                                    placeholder='Select Quotation Account'
                                    onChange={(quo) => setSelectedQuotation(quo)}
                                />
                            </VStack>
                        </Card>

                        <Card p={4} width={'100%'}>
                            <VStack w='100%'>
                                <Text fontSize='1xl' fontWeight={'bold'}>Select Plans</Text>
                                <HStack>
                                    <Select
                                        variant='filled'
                                        options={plansDropdown}
                                        onChange={handlePlanChange}
                                        placeholder='Select Plans'
                                    />
                                    <Button colorScheme="purple" onClick={handleAddPlan}>Add</Button>
                                </HStack>
                            </VStack>
                        </Card>
                    </HStack>


                    <Card p={4} width={'100%'}>
                        <HStack>
                            <VStack alignItems='start'>
                                <Text fontSize='1xl' fontWeight={'bold'}>Client Name</Text>
                                <Input
                                    placeholder='Client Name'
                                    onChange={(val) => setClientName(val.target.value)}
                                />
                            </VStack>

                            <VStack alignItems='start'>
                                <Text fontSize='1xl' fontWeight={'bold'}>Client Address</Text>
                                <Input
                                    placeholder='Client Address'
                                    onChange={(val) => setClientAddress(val.target.value)}
                                />
                            </VStack>

                            <VStack alignItems='start'>
                                <Text fontSize='1xl' fontWeight={'bold'}>Subject</Text>
                                <Input
                                    placeholder='Subject'
                                    onChange={(val) => setClientSub(val.target.value)}
                                />
                            </VStack>

                        </HStack>
                    </Card>


                    {addedPlans !== 0 ?
                        <Card p={4} width={'100%'}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Plan Name</th>
                                        <th>Validity</th>
                                        {/* <th>From</th>
                                        <th>To</th> */}
                                        {/* <th>Quantity</th> */}
                                        <th>Rate</th>
                                        <th>Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedPlans.map((plan, index) => (
                                        <tr key={plan.id}>
                                            <td>{plan.title}</td>
                                            <td>{plan.duration_in_days} days</td>
                                            {/* <td>
                                                <SingleDatepicker
                                                    name="date-input"
                                                    date={plan.date_from_date}
                                                    configs={{
                                                        dateFormat: 'dd-MM-yyyy',
                                                    }}
                                                    onDateChange={(d) => {
                                                        // setDateTo(d);
                                                        plan.date_from = moment(d).format("yyyy-MM-DD");
                                                        plan.date_from_date = d;
                                                        let newUpdatedPlan = plan;
                                                        let oldarr = addedPlans;
                                                        oldarr[addedPlans.indexOf(plan)] = newUpdatedPlan;
                                                        setAddedPlans(oldarr);
                                                        calculateTotal();
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <SingleDatepicker
                                                    name="date-input"
                                                    date={plan.date_to_date}
                                                    configs={{
                                                        dateFormat: 'dd-MM-yyyy',
                                                    }}
                                                    onDateChange={(d) => {
                                                        // setDateTo(d);
                                                        plan.date_to = moment(d).format("yyyy-MM-DD");
                                                        plan.date_to_date = d;
                                                        let newUpdatedPlan = plan;
                                                        let oldarr = addedPlans;
                                                        oldarr[addedPlans.indexOf(plan)] = newUpdatedPlan;
                                                        setAddedPlans(oldarr);
                                                        calculateTotal();
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    width={'100px'}
                                                    type='number'
                                                    defaultValue={plan.qty}
                                                    onChange={(val) => {
                                                        // console.log(val.target.value);
                                                        plan.qty = val.target.value || 1;
                                                        let newUpdatedPlan = plan;
                                                        let oldarr = addedPlans;
                                                        oldarr[addedPlans.indexOf(plan)] = newUpdatedPlan;
                                                        setAddedPlans(oldarr);
                                                        calculateTotal();
                                                        // setTotalAmount(totalAmount + (plan.cost * plan.qty)); 
                                                    }}
                                                />
                                            </td> */}
                                            <td>₹{plan.cost}</td>
                                            <td>₹{parseInt(plan.cost) * plan.qty}</td>
                                            <td><Button onClick={() => handleRemovePlan(index)}><MdDelete /></Button></td>
                                        </tr>
                                    ))}

                                    <tr style={{ backgroundColor: "transparent" }}>
                                        <td></td>
                                        {/* <td></td>
                                        <td></td>
                                        <td></td> */}
                                        <td></td>
                                        <td>Total</td>
                                        <td>₹{totalAmount}</td>
                                        <td></td>
                                    </tr>


                                </tbody>
                            </table>
                            <Link to={`/quotationmasters/print`} state={{
                                'clientName': clientName,
                                'clientAddress': clientAddress,
                                'clientSub': clientSub,
                                'addedPlans': addedPlans,
                                'totalAmount': totalAmount,
                                'items': addedPlans,
                                'date': new Date(),
                                'quotation': selectedQuotation.value,
                                'total': totalAmount,
                            }}>
                                <Button mt={6} colorScheme="purple">Print</Button>
                            </Link>
                        </Card>
                        : <></>}
                </VStack>
            </Container>
        </>
    )
}
