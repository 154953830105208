import {
    Card,
    Container,
    VStack,
    Text,
    FormControl,
    FormLabel,
    Button,
    Input,
} from '@chakra-ui/react'
import { React } from 'react'
import { useFormik } from 'formik';
import { useCustomers } from '../providers/CustomersProvider';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function AddCustomer() {
    var { addCustomer } = useCustomers();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            gst: "",
            note: "",
            address: ""
        },
        onSubmit: async (values) => {
            try {
                await addCustomer(values.name, values.email, values.phone, values.gst, values.note, values.address);
                toast.success("Customer Added Successfully !",);
                navigate(-1);
            } catch (error) {
                toast.error("Error Adding !");
            }
            console.log(values);
        }
    });
    return (
        <Container p={4} maxW='3xl'>
            <VStack >
                <Card p={4} width={'100%'}><Text fontSize='2xl' fontWeight={'bold'}>Add Customer</Text></Card>

                <Card p={6} width={'100%'}>
                    <form onSubmit={formik.handleSubmit}>
                        <VStack spacing={4} align="flex-start">
                            <FormControl>
                                <FormLabel htmlFor="name">Name</FormLabel>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="phone">Phone</FormLabel>
                                <Input
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="gst">GST</FormLabel>
                                <Input
                                    id="gst"
                                    name="gst"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.gst}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="note">Note</FormLabel>
                                <Input
                                    id="note"
                                    name="note"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.note}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="address">Address</FormLabel>
                                <Input
                                    id="address"
                                    name="address"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.address}
                                />
                            </FormControl>
                            <Button type="submit" colorScheme="purple" py='6' width="full">
                                Add
                            </Button>
                        </VStack>
                    </form>
                </Card>
            </VStack>
        </Container>
    )
}
