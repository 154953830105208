import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { useAuth } from "./AuthProvider";

const CustomersContext = createContext();

const CustomersProvider = ({ children }) => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logged, logout } = useAuth();

    // const { token, logged, logout } = useAuth();


    async function addCustomer(name, email, phone, gst, note, address) {
        setLoading(true);
        try {
            await axios.post(`${url}/customer`, {
                "name": name,
                "email": email,
                "phone": phone,
                "gst": gst,
                "note": note,
                "address": address,
            },
                // {
                //     headers: {
                //         Authorization: 'Bearer ' + token
                //     },
                // }
            );
            await getCustomers();
            return;
        } catch (error) {
            console.error(error);
            return "err";
        }
    }

    async function deleteCustomer(id) {
        try {
            await axios.delete(`${url}/customer/${id}`);
            await getCustomers();
            return;
        }
        catch (err) {
            console.log(err)
        }
    }

    async function editCustomer(id, name, email, phone, gst, note, address) {
        setLoading(true);
        try {

            await axios.put(`${url}/customer/${id}`, {
                "name": name,
                "email": email,
                "phone": phone,
                "gst": gst,
                "note": note,
                "address": address
            }
            );
            await getCustomers();
            return;
        }
        catch (err) {
            console.log(err);
        }
    }

    async function getCustomers() {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/customer`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                }
            );
            setLoading(false);
            setCustomers(data);

        } catch (error) {
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    useEffect(() => {
        getCustomers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            customers,
            loading,
            getCustomers,
            addCustomer,
            deleteCustomer,
            editCustomer
        }),
        [loading, customers]); // eslint-disable-line react-hooks/exhaustive-deps

    // Provide the authentication context to the children components
    return (
        <CustomersContext.Provider value={contextValue}>{children} </CustomersContext.Provider>
    );
};

export const useCustomers = () => {
    return useContext(CustomersContext);
};

export default CustomersProvider;