import {
    Card,
    Container,
    VStack,
    Text,
    FormControl,
    FormLabel,
    Button,
    Input,
} from '@chakra-ui/react'
import { React } from 'react'
import { useFormik } from 'formik';
import { useQuotationMasters } from '../providers/QuotationMastersProvider';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function AddQuotationMasters() {
    var { addQuotationMaster } = useQuotationMasters();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            "name": "",
            "address": "",
            "gst": "",
            "to_name": "",
            "to_address": "",
            "prop_name": "",
            "prop_number": ""
        },
        onSubmit: async (values) => {
            try {
                await addQuotationMaster(values.name, values.address, values.gst, values.to_name, values.to_address, values.prop_name, values.prop_number);
                toast.success("Quotation Master Added Successfully !",);
                navigate(-1);
            } catch (error) {
                toast.error("Error Adding !");
            }
            console.log(values);
        }
    });
    return (
        <Container p={4} maxW='3xl'>
            <VStack >
                <Card p={4} width={'100%'}><Text fontSize='2xl' fontWeight={'bold'}>Add Quotation Masters</Text></Card>

                <Card p={6} width={'100%'}>
                    <form onSubmit={formik.handleSubmit}>
                        <VStack spacing={4} align="flex-start">
                            <FormControl>
                                <FormLabel htmlFor="name">Name</FormLabel>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="address">Address</FormLabel>
                                <Input
                                    id="address"
                                    name="address"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.address}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="gst">GST</FormLabel>
                                <Input
                                    id="gst"
                                    name="gst"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.gst}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="to_name">To Name</FormLabel>
                                <Input
                                    id="to_name"
                                    name="to_name"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.to_name}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="to_address">To Address</FormLabel>
                                <Input
                                    id="to_address"
                                    name="to_address"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.to_address}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="prop_name">Prop Name</FormLabel>
                                <Input
                                    id="prop_name"
                                    name="prop_name"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.prop_name}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="prop_number">Prop Number</FormLabel>
                                <Input
                                    id="prop_number"
                                    name="prop_number"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.prop_number}
                                />
                            </FormControl>
                            <Button type="submit" colorScheme="purple" py='6' width="full">
                                Add
                            </Button>
                        </VStack>
                    </form>
                </Card>
            </VStack>
        </Container>
    )
}

