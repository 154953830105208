import { useFormik } from "formik";
import {
    Box,
    Button,
    Flex,
    Text,
    FormControl,
    FormLabel,
    Input,
    VStack
} from "@chakra-ui/react";
import { useAuth } from "../../providers/AuthProvider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Login() {

    const { login } = useAuth();
    const [error, setError] = useState();

    const [info, setinfo] = useState(null);

    const navigate = useNavigate();


    const formik = useFormik({

        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: async (values) => {
            // alert(JSON.stringify(values, null, 2));

            setinfo("Logging in...");
            var res = await login(values.email, values.password);
            setinfo(null);
            console.log(res)
            if (res === "err") {
                setError("Invalid Credentials !");
            } else {
                navigate('/customer');
            }

        }

    });

    return (
        <Flex bg="gray.100" align="center" justify="center" h="100vh">
            <Box bg="white" p={6} minW={'500px'} rounded="md">
                <form onSubmit={formik.handleSubmit}>
                    <VStack spacing={4} align="flex-start">
                        <Text fontSize='2xl' as='h3'>Login to Continue</Text>

                        {error ? <Box bg='red' w='100%' py='1' textAlign='center' color='white' > {error} </Box> : <></>}
                        {info && <Text color='blue'>{info}</Text>}
                        <FormControl>
                            <FormLabel htmlFor="email">Username</FormLabel>
                            <Input
                                id="email"
                                name="email"
                                type="text"
                                variant="filled"
                                required={true}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <Input
                                id="password"
                                name="password"
                                type="password"
                                required={true}
                                variant="filled"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />
                        </FormControl>
                        <Button type="submit" colorScheme="primary" py='6' width="full">
                            Login
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Flex>
    );
}