import {
    Container,
    VStack,
    Card,
    HStack,
    Button,
    Text,
    CircularProgress,
    Center,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { ConfirmDialog } from '../utils/ConfirmDialog';
import { useOrders } from '../../providers/OrdersProvider';
import Moment from 'react-moment';

export default function Orders() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);
    const { orders, deleteOrders, getOrders, loading } = useOrders();

    const handleConfirm = async () => {
        //when the user confirms the dialog
        try {
            await deleteOrders(selectedId);
            setIsOpen(false);
        } catch (error) {

        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    // function calculateTotal(p) {
    //     let cgstCost = (p.cost / 100) * p.cgst;
    //     let sgstCost = (p.cost / 100) * p.sgst;
    //     console.log(p.cost + cgstCost + sgstCost)
    //     return p.cost + cgstCost + sgstCost;
    // }

    useEffect(() => {
        getOrders();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    if (loading) {
        return (
            <>
                <Center>
                    <CircularProgress isIndeterminate color='green' />
                </Center>
            </>
        )
    }

    return (
        <Container p='4' maxW='5xl'>
            <VStack width='100%' spacing={4}>

                <Card borderRadius='md' p='4' width={'100%'}>
                    <HStack justifyContent='space-between'>
                        <Text fontWeight='bold' className='customfont' fontSize='2xl'>Orders</Text>
                        <Link to={'/orders/add'}> <Button colorScheme='purple'>Add</Button> </Link>
                    </HStack>
                </Card>

                <Card width={'100%'} borderRadius='md' p={6}>
                    <table>
                        <thead>
                            <tr>
                                <th py='4' fontSize='sm'>S.no</th>
                                <th py='4' fontSize='sm'>Order Id</th>
                                <th py='4' fontSize='sm'>User Name</th>
                                <th py='4' fontSize='sm'>User Email</th>
                                <th py='4' fontSize='sm'>Order Date</th>
                                {/* <th py='4' fontSize='sm'>Product</th>
                                <th py='4' fontSize='sm'>Days</th>
                                <th py='4' fontSize='sm'>HSNcode</th> */}
                                <th py='4' fontSize='sm'>Total</th>
                                <th py='4' fontSize='sm'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((p, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td py='4' align='center' fontSize='sm'>{orders.indexOf(p) + 1}</td>
                                        <td py='4' align='center' fontSize='sm'> {p.id}</td>
                                        <td py='4' align='center' fontSize='sm'> {p.user.name}</td>
                                        <td py='4' align='center' fontSize='sm'> {p.user.email}</td>
                                        <td><Moment date={p.orderdate} format='DD/MM/YYYY' /></td>
                                        {/* <td py='4' align='center' fontSize='sm'> {p.plan_title}</td> */}
                                        {/* <td py='4' align='center' fontSize='sm'> {p.duration_in_days}</td> */}
                                        {/* <td py='4' align='center' fontSize='sm'> {p.hsncode}</td> */}
                                        <td py='4' align='center' fontSize='sm'> {Math.ceil(p.grand_total)}</td>
                                        <td>
                                            <HStack justifyContent='center'>
                                                <Button size='sm' onClick={() => { setSelectedId(p.orderid); setIsOpen(true); }} colorScheme='red'>Delete</Button>

                                                <Link to='/orders/details' state={p}>
                                                    <Button size='sm' colorScheme='whatsapp'>Print</Button>
                                                </Link>

                                            </HStack>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                </Card>

            </VStack>

            <ConfirmDialog
                isOpen={isOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                title="Are you sure?"
                body="This action cannot be undone."
            />
        </Container >
    )
}



