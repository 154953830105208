import { Container, Card, Text, Button, Box, VStack, HStack, Image, Divider } from '@chakra-ui/react'
import moment from 'moment';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

export default function OrderDetails() {

    const componentRef = useRef();

    const { state } = useLocation();


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    console.log(state);

    return (
        <>
            <Container maxW='5xl' my='2'>
                <VStack>

                    <Card p='5' w='100%'>
                        <HStack justifyContent='space-between' w='100%'>
                            <Text fontWeight='800'>Order Details</Text>
                            <Button onClick={handlePrint}>Print</Button>
                        </HStack>
                    </Card>

                    <Box bg='white' ref={componentRef} shadow='md' p='5' style={{ height: 'auto', width: '21cm', aspectRatio: '1.41/1' }}>

                        <Container border='2px solid #1c1c1c' width='100%' maxW='100%'>

                            <HStack alignItems='start' justifyContent='space-between' p='0' w='100%'>

                                <Box p='2'>
                                    <Image border='0px solid #1c1c1c' alt='Logo' src='/logo.png' />
                                </Box>

                                <VStack border='0px solid #1c1c1c' w='100%' spacing='0' justifyContent='start'>
                                    <Text mt='2' fontWeight='800'>Avneesh Networks</Text>
                                    <Text>152 Shivling Nagar Akola Bypass</Text>
                                    <Text>Hingoli 431513 Maharashtra</Text>

                                    <HStack border='2px solid #1c1c1c' w='100%' justifyContent='space-between' p='1'>
                                        <Text fontSize='14px'> State Code27</Text>
                                        <Text fontSize='14px'> GSTIN No 27ASIPR1471J1ZD</Text>
                                    </HStack>

                                    <HStack border='2px solid #1c1c1c' w='100%' justifyContent='space-between' p='1'>
                                        <Text fontSize='14px'> Mob No 9404070283</Text>
                                        <Text fontSize='14px'> Email: avneeshnetworks@gmail.com</Text>
                                    </HStack>

                                    <HStack border='2px solid #1c1c1c' w='100%' justifyContent='space-between' p='1'>
                                        <Text fontSize='14px'> INVOICE NO : {moment(state.date).format("DD")}{moment(state.date).format("MM")}{moment(state.date).format("yyyy")}{state.id}</Text>
                                        <Text fontSize='14px'> DATE : {moment(state.date).format("DD/MM/yyyy")}</Text>
                                    </HStack>

                                    <HStack border='2px solid #1c1c1c' w='100%' justifyContent='space-between' p='1'>
                                        <Text fontSize='14px'> Billing From :   {moment(state.items[0].date_from).format("DD/MM/yyyy")}</Text>
                                        <Text fontSize='14px'> Billing To : {moment(state.items[0].date_to).format("DD/MM/yyyy")} </Text>
                                    </HStack>


                                </VStack>
                            </HStack>


                            <Text>To</Text>
                            <HStack justifyContent='space-between' my='1'>
                                <Text>Name : {state.user.name}</Text>
                                <Text>GST : {state.user.gst}</Text>
                            </HStack>
                            <HStack justifyContent='space-between' my='1'>
                                <Text>Email : {state.user.email}</Text>
                                <Text>Phone : {state.user.phone}</Text>
                            </HStack>
                            <Text>Address : {state.user.address}</Text>

                            <Divider my='2' />

                            <table style={{ width: '100%' }}>
                                <thead>
                                    <th>S.No</th>
                                    <th>Particular</th>
                                    <th>Validity</th>
                                    <th>HSN Code</th>
                                    <th>Qty</th>
                                    <th>Rate</th>
                                    <th>Amount</th>
                                </thead>
                                <tbody>
                                    {state.items.map((item, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>1</td>
                                                <td>{item.plan_title}</td>
                                                <td>{item.duration_in_days}</td>
                                                <td>{item.hsncode}</td>
                                                <td>{item.qty}</td>
                                                <td>{item.cost}</td>
                                                <td>{item.cost * item.qty}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>CGST</td>
                                        <td>{state.items[0].cgst}%</td>
                                        <td>{(state.cgst_total.toFixed(2))}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>SGST</td>
                                        <td>{state.items[0].sgst}%</td>
                                        <td>{state.sgst_total.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Sub Total</td>
                                        <td> </td>
                                        <td>{state.grand_total}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Round Off</td>
                                        <td> </td>
                                        <td>{(Math.ceil(state.grand_total) - state.grand_total).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Net Amount</td>
                                        <td> </td>
                                        <td>{Math.ceil(state.grand_total)}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <HStack justifyContent='end' my='5'>
                                <Image src='/sign.jpeg' w='120px' />
                            </HStack>

                            <Text textAlign='center' textDecoration='underline'>This is a computer generated bill</Text>
                        </Container>
                    </Box>
                </VStack>
            </Container>
        </>
    )
}