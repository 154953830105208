
import {
    Box,
    Button,
    HStack,
    useDisclosure,
    Flex,
    IconButton,
    Stack,
    Text,
    Container,
} from '@chakra-ui/react'
import React from 'react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BiExit } from 'react-icons/bi';
import { useAuth } from '../../providers/AuthProvider';
import { Link } from 'react-router-dom';

export default function Navbar() {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { logout } = useAuth();

    return (
        <>
            <Box bg='white' px={4}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={5} alignItems={'center'}>

                        <HStack as={'nav'} spacing={2} display={{ base: 'none', md: 'flex' }}>

                            <Container my={12} mx={6}>
                                <img src='logo.png' alt='logo' height={"100px"} width={"100px"} />
                            </Container>

                            <Link to={'/customer'}>
                                <Button>
                                    <Text fontWeight='600'>Customers</Text>
                                </Button>
                            </Link>

                            <Link to={'/plans'}>
                                <Button>
                                    <Text fontWeight='600'>Plan</Text>
                                </Button>
                            </Link>

                            <Link to={'/orders'}>
                                <Button>
                                    <Text fontWeight='600'>Orders</Text>
                                </Button>
                            </Link>

                            <Link to={'/orders/add'}>
                                <Button>
                                    <Text fontWeight='600'>Add Order</Text>
                                </Button>
                            </Link>

                            <Link to={'/quotationmasters'}>
                                <Button>
                                    <Text fontWeight='600'>Quotation Master</Text>
                                </Button>
                            </Link>

                            <Link to={'/quotationmasters/generate'}>
                                <Button>
                                    <Text fontWeight='600'>Generate Quotation</Text>
                                </Button>
                            </Link>

                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'}>
                        <Button
                            onClick={logout}
                            variant={'solid'}
                            colorScheme={'red'}
                            size={'md'}
                            mr={4}
                            borderRadius='md'
                            rightIcon={<BiExit />}>
                            Logout
                        </Button>
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>


                            <Link to={'/customer'}>
                                <Button>
                                    <Text fontWeight='600'>Customers</Text>
                                </Button>
                            </Link>

                            <Link to={'/plans'}>
                                <Button>
                                    <Text fontWeight='600'>Plan</Text>
                                </Button>
                            </Link>

                            <Link to={'/orders'}>
                                <Button>
                                    <Text fontWeight='600'>Orders</Text>
                                </Button>
                            </Link>

                            <Link to={'/orders/add'}>
                                <Button>
                                    <Text fontWeight='600'>Add Order</Text>
                                </Button>
                            </Link>

                            <Link to={'/quotationmasters'}>
                                <Button>
                                    <Text fontWeight='600'>Quotation Master</Text>
                                </Button>
                            </Link>

                            <Link to={'/quotationmasters/generate'}>
                                <Button>
                                    <Text fontWeight='600'>Generate Quotation</Text>
                                </Button>
                            </Link>

                        </Stack>
                    </Box>
                ) : null}
            </Box>


            <ToastContainer />
        </>
    )
}
