import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider';
import CustomersProvider from '../../providers/CustomersProvider';
import PlansProductsProvider from '../../providers/PlansProductsProvider';
import Navbar from '../common/Navbar';
import OrdersProvider from '../../providers/OrdersProvider';
import QuotationMasters from '../../providers/QuotationMastersProvider';
export default function PrivateRoutes() {
    const { loading, logged } = useAuth();

    if (loading) {
        return (
            <React.Fragment>
                <p>Loading...</p>
            </React.Fragment>
        )
    }
    return (
        logged ?
            <>
                <CustomersProvider>
                    <PlansProductsProvider>
                        <OrdersProvider>
                            <QuotationMasters>
                                <Navbar />
                                <Outlet />
                            </QuotationMasters>
                        </OrdersProvider>
                    </PlansProductsProvider>
                </CustomersProvider>
            </>
            : <Navigate to='/login' />
    )
}

