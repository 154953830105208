import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { useAuth } from "./AuthProvider";

const PlansProductsContext = createContext();

const PlansProductsProvider = ({ children }) => {
    const [plansproducts, setPlansProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logged, logout } = useAuth();

    // const { token, logged, logout } = useAuth();


    async function addPlansProducts(title, is_subscription_plan, duration_in_days, note, hsncode, cost, sgst_percent, cgst_percent, igst_percent) {
        setLoading(true);
        try {
            await axios.post(`${url}/plansproducts`, {
                "title": title,
                "is_subscription_plan": is_subscription_plan,
                "duration_in_days": duration_in_days,
                "note": note,
                "hsncode": hsncode,
                "cost": cost,
                "sgst_percent": sgst_percent,
                "cgst_percent": cgst_percent,
                "igst_percent": igst_percent
            },
                // {
                //     headers: {
                //         Authorization: 'Bearer ' + token
                //     },
                // }
            );
            await getPlansProducts();
            return;
        } catch (error) {
            console.error(error);
            return "err";
        }
    }

    async function deletePlansProducts(id) {
        try {
            await axios.delete(`${url}/plansproducts/${id}`);
            await getPlansProducts();
            return;
        }
        catch (err) {
            console.log(err)
        }
    }

    async function editPlansProducts(id, title, is_subscription_plan, duration_in_days, note, hsncode, cost, sgst_percent, cgst_percent, igst_percent) {
        setLoading(true);
        try {

            await axios.put(`${url}/plansproducts/${id}`, {
                "title": title,
                "is_subscription_plan": is_subscription_plan,
                "duration_in_days": duration_in_days,
                "note": note,
                "hsncode": hsncode,
                "cost": cost,
                "sgst_percent": sgst_percent,
                "cgst_percent": cgst_percent,
                "igst_percent": igst_percent
            }
            );
            await getPlansProducts();
            return;
        }
        catch (err) {
            console.log("Error from catch")
            console.log(err);
        }
    }

    async function getPlansProducts() {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/plansproducts`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                }
            );
            setLoading(false);
            setPlansProducts(data);

        } catch (error) {
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    useEffect(() => {
        getPlansProducts();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            plansproducts,
            loading,
            getPlansProducts,
            addPlansProducts,
            deletePlansProducts,
            editPlansProducts
        }),
        [loading, plansproducts]); // eslint-disable-line react-hooks/exhaustive-deps

    // Provide the authentication context to the children components
    return (
        <PlansProductsContext.Provider value={contextValue}>{children} </PlansProductsContext.Provider>
    );
};

export const usePlansProducts = () => {
    return useContext(PlansProductsContext);
};

export default PlansProductsProvider;