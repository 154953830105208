import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './components/auth/Login';
import AuthProvider from './providers/AuthProvider';
import PrivateRoutes from './components/utils/PrivateRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Customers from './components/Customers';
import AddCustomer from './components/AddCustomer';
import EditCustomer from './components/EditCustomer';
import PlanProducts from './components/PlanProducts';
import AddPlansProducts from './components/AddPlansProducts';
import Orders from './components/orders/Orders';
import OrderDetails from './components/orders/OrderDetails';
import AddOrders from './components/AddOrders';
import EditPlansProduct from './components/EditPlansProduct';
import QuotationMasters from './components/QuotationMasters';
import AddQuotationMasters from './components/AddQuotationMasters';
import EditQuotationMasters from './components/EditQuotationMasters';
import PrintQuotation from './components/PrintQuotation';
import GenerateQuotation from './components/GenerateQuotation';

function App() {
  return (
    <AuthProvider key={'authprovider'}>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<Orders />} />

            <Route path='/customer' element={<Customers />} />
            <Route path='/customer/add' element={<AddCustomer />} />
            <Route path='/customer/edit/:id' element={<EditCustomer />} />

            <Route path='/plans' element={<PlanProducts />} />
            <Route path='/plans/add' element={<AddPlansProducts />} />
            <Route path='/plans/edit/:id' element={<EditPlansProduct />} />

            <Route path='/orders' element={<Orders />} />
            <Route path='/orders/add' element={<AddOrders />} />
            <Route path='/orders/details' element={<OrderDetails />} />
            {/* <Route path='/orders/edit/:id' element={<EditCustomer />} /> */}

            <Route path='/quotationmasters' element={<QuotationMasters />} />
            <Route path='/quotationmasters/add' element={<AddQuotationMasters />} />
            <Route path='/quotationmasters/edit/:id' element={<EditQuotationMasters />} />
            <Route path='/quotationmasters/generate' element={<GenerateQuotation />} />
            <Route path='/quotationmasters/print' element={<PrintQuotation />} />

          </Route>
          <Route path='/login' element={<Login />} />
        </Routes>
      </Router>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
