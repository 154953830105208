import {
    Card,
    Container,
    VStack,
    Text,
    FormControl,
    FormLabel,
    Button,
    Input,
    HStack,
} from '@chakra-ui/react'
import { React } from 'react'
import { useFormik } from 'formik';
import { usePlansProducts } from '../providers/PlansProductsProvider';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
export default function AddPlansProducts() {
    var { addPlansProducts } = usePlansProducts();
    const navigate = useNavigate();

    const formik = useFormik({

        initialValues: {
            title: '',
            is_subscription_plan: false,
            duration_in_days: 0,
            note: '',
            hsncode: '',
            cost: 0,
            sgst_percent: 0,
            cgst_percent: 0,
            igst_percent: 0,
        },
        onSubmit: async (values) => {
            try {
                await addPlansProducts(values.title, values.is_subscription_plan, values.duration_in_days, values.note, values.hsncode, values.cost, values.sgst_percent, values.cgst_percent, values.igst_percent);
                toast.success("Plans Products Added Successfully !", {
                });
                navigate(-1);
            } catch (error) {
                toast.error("Error Adding !");
            }
        }
    });
    return (
        <Container p={4} maxW='3xl'>
            <VStack >
                <Card p={4} width={'100%'}><Text fontSize='2xl' fontWeight={'bold'}>Add Plans Products</Text></Card>

                <Card p={6} width={'100%'}>
                    <form onSubmit={formik.handleSubmit}>
                        <VStack spacing={4} align="flex-start">
                            <FormControl>
                                <FormLabel htmlFor="title">Title</FormLabel>
                                <Input
                                    id="title"
                                    name="title"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                />
                            </FormControl>

                            <FormControl>
                                <HStack>
                                    <FormLabel pt={3} htmlFor="is_subscription_plan">
                                        Subscription Plan
                                    </FormLabel>

                                    <input
                                        style={{ width: 20, height: 20 }}
                                        type="checkbox"
                                        id="is_subscription_plan"
                                        name="is_subscription_plan"
                                        checked={formik.values.is_subscription_plan}
                                        onChange={formik.handleChange}
                                    />
                                </HStack>
                            </FormControl>

                            {formik.values.is_subscription_plan && (
                                <FormControl>
                                    <FormLabel htmlFor="duration_in_days">Duration (in days):</FormLabel>
                                    <Input
                                        id="duration_in_days"
                                        name="duration_in_days"
                                        type="number"
                                        variant="filled"
                                        required={true}
                                        onChange={formik.handleChange}
                                        value={formik.values.duration_in_days}
                                    />
                                </FormControl>
                            )}

                            <FormControl>
                                <FormLabel htmlFor="hsncode">HSN/SAC Code</FormLabel>
                                <Input
                                    id="hsncode"
                                    name="hsncode"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.hsncode}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="cost">Cost</FormLabel>
                                <Input
                                    id="cost"
                                    name="cost"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.cost}
                                />
                            </FormControl>

                            {/* <FormControl>
                                <FormLabel htmlFor="sgst_percent">SGST Percent</FormLabel>
                                <Input
                                id="sgst_percent"
                                    name="sgst_percent"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.sgst_percent}
                                    />
                                    </FormControl>
                                    
                                    <FormControl>
                                    <FormLabel htmlFor="cgst_percent">CGST Percent</FormLabel>
                                    <Input
                                    id="sgst_percent"
                                    name="sgst_percent"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.sgst_percent}
                                    />
                                    </FormControl>
                                    
                                    <FormControl>
                                    <FormLabel htmlFor="igst_percent">IGST Percent</FormLabel>
                                    <Input
                                    id="igst_percent"
                                    name="igst_percent"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.igst_percent}
                                    />
                                </FormControl> */}

                            <FormControl>
                                <FormLabel htmlFor="note">Note</FormLabel>
                                <Input
                                    id="note"
                                    name="note"
                                    type="text"
                                    variant="filled"
                                    onChange={formik.handleChange}
                                    value={formik.values.note}
                                />
                            </FormControl>

                            <Button type="submit" colorScheme="purple" py='6' width="full">
                                Add
                            </Button>
                        </VStack>
                    </form>
                </Card>
            </VStack>
        </Container>
    )
}

